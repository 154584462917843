.Body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  font-size: calc(3vmin);
  color: white;
  font-family: Macondo, cursive;
  /*width: 800px;*/
}

/*My stuff*/

.background{
  height:300rem;
  width:auto;
  background:linear-gradient(#88A79780, transparent);
  background-color:#3BEDB8; /*this your primary color*/
}
