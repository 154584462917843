.container {
  display: grid;
  height: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  position: relative;
}

img {
  width: 50px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  width: 50px;
}

.altGrid{
  display: grid;
  /*grid-template-rows: 1fr 1fr;*/
  width: 50px;
}
.item:first-child{
  grid-column: 2/3;
}

.item:nth-child(2) {
  grid-column-start: 1;
  grid-column-end: 2.5;
}

.item:nth-child(3) {
  grid-column-start: 2.5;
  grid-column-end: 4;
}
.item:last-child{
  grid-column: 2/3;
}

.altGridSpan{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-columns: span 2;
  grid-template-rows: 1fr 1fr;
  width: 50px;
}

.grid>* {
  padding: 1px;
  box-shadow: inset 0 0 0 1px #fff;
}




